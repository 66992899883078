import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Image } from "react-bootstrap"

import GridList from "@material-ui/core/GridList"
import GridListTile from "@material-ui/core/GridListTile"

import mw1 from "../images/home-carousel/mw1.jpg"
import mw2 from "../images/home-carousel/mw2.jpg"
import mw3 from "../images/home-carousel/mw3.jpg"
import mw4 from "../images/home-carousel/mw4.jpg"
import mw5 from "../images/home-carousel/mw5.jpg"
import mw6 from "../images/home-carousel/mw6.jpg"
import mw7 from "../images/home-carousel/mw7.jpg"
import mw8 from "../images/home-carousel/mw8.jpg"
import mw9 from "../images/home-carousel/mw9.jpg"
import mw10 from "../images/home-carousel/mw10.jpg"
import mw11 from "../images/home-carousel/mw11.jpg"
import mw12 from "../images/home-carousel/mw12.jpg"
import mw13 from "../images/home-carousel/mw13.jpg"

import ha1 from "../images/home-carousel/ha1.jpg"
import ha2 from "../images/home-carousel/ha2.jpg"
import ha3 from "../images/home-carousel/ha3.jpg"
import ha4 from "../images/home-carousel/ha4.jpg"
import ha5 from "../images/home-carousel/ha5.jpg"
import ha6 from "../images/home-carousel/ha6.jpg"
import ha7 from "../images/home-carousel/ha7.jpg"
import ha8 from "../images/home-carousel/ha8.jpg"
import ha9 from "../images/home-carousel/ha9.jpg"
import ha10 from "../images/home-carousel/ha10.jpg"
import ha11 from "../images/home-carousel/ha11.jpg"

import bridal_bouquet from "../images/home-carousel/bridal-bouquet.jpg"
import bus_on_main from "../images/home-carousel/bus-on-main.jpg"
import cake from "../images/home-carousel/cake.jpg"
import ceremony from "../images/home-carousel/ceremony.jpg"
import father_daughter_dance from "../images/home-carousel/father-daughter-dance.jpg"
import flower_fairies from "../images/home-carousel/flower-fairies.jpg"
import horse1 from "../images/home-carousel/horse1.jpg"
import jenga from "../images/home-carousel/jenga.jpg"
import ladies_on_bridge from "../images/home-carousel/ladies-on-bridge.jpg"
import sacred_moment from "../images/home-carousel/sacred-moment.jpg"
import wilson from "../images/home-carousel/wilson.jpg"

const tileData = [
  { img: bus_on_main },
  { img: ceremony },
  { img: father_daughter_dance },

  { img: sacred_moment, cols: 2 },
  { img: wilson }, // tall

  { img: flower_fairies },
  { img: horse1 },
  { img: mw3 },

  { img: bridal_bouquet, rows: 2 }, // tall
  { img: mw1, cols: 2 },

  { img: mw2 },
  { img: mw4 },
  { img: mw5 },

  { img: mw10, cols: 2 },
  { img: cake, rows: 2 }, // tall

  { img: mw6 },
  { img: mw7 },
  { img: mw8 },

  { img: ha7 }, // tall. arcade drum game
  { img: ha3, cols: 2 },

  { img: mw9 },
  { img: mw11 },
  { img: mw12 },

  { img: ha2, cols: 2 },
  { img: ladies_on_bridge }, // tall

  { img: mw13 },
  { img: ha1 },
  { img: ha5 },

  { img: ha4 }, // tall. bowl
  { img: ha11, cols: 2 },

  { img: ha6 },
  { img: ha8 },
  { img: ha9 },

  { img: ha10, cols: 2 },
  { img: jenga }, // tall
]

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: "rgb(240, 240, 240)",
  },
  gridList: {
    width: "auto",
    height: "auto",
  },
}))
export default function ImageGridList() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <GridList cellHeight="auto" className={classes.gridList} cols={3}>
        {tileData.map(tile => (
          <GridListTile
            key={tile.img}
            cols={tile.cols || 1}
            rows={tile.rows || 1}
          >
            <Image fluid src={tile.img} alt={tile.title} />
          </GridListTile>
        ))}
      </GridList>
    </div>
  )
}
