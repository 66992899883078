import React from "react"
import { Container } from "react-bootstrap"
import ImageGridList from "../components/gridlist"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/style.scss"

const GalleryPage = () => (
  <Layout pageInfo={{ pageName: "gallery" }}>
    <SEO
      title="Gallery"
      keywords={[`green`, `velvet`, `concierge`, `telluride`, `wedding`]}
    />

    <Container className="main-container">
      <ImageGridList />
    </Container>
  </Layout>
)

export default GalleryPage
